/* eslint-disable array-callback-return */
import { useCallback } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import type { PointerEvent } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { PageContent, PageElement } from 'types'
class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          (event.target as any).tagName.toLowerCase() === 'path' // MUI SVG icons
        ) {
          return false
        }

        return true
      },
    },
  ]
}

export function PageElementEditorField(props: {
  content: PageContent
  updateContent: (content: PageContent) => void
}) {
  const addTextPageElement = useCallback(
    (index: number) => {
      const newContent = props.content.slice()
      newContent.splice(index, 0, {
        type: 'TEXT',
        text: 'Placeholder text',
        name: 'New text element',
        id: uuidv4(),
      })
      props.updateContent(newContent)
    },
    [props]
  )

  const addGalleryPageElement = useCallback(
    (index: number) => {
      const newContent = props.content.slice()
      newContent.splice(index, 0, {
        type: 'GALLERY',
        name: 'New gallery element',
        photoIds: [],
        id: uuidv4(),
      })
      props.updateContent(newContent)
    },
    [props]
  )

  const addTripleImagePageElement = useCallback(
    (index: number) => {
      const newContent = props.content.slice()
      newContent.splice(index, 0, {
        type: 'TRIPLE_IMAGE',
        name: 'New triple image element',
        photoIds: [],
        text: 'Placeholder text',
        id: uuidv4(),
      })
      props.updateContent(newContent)
    },
    [props]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      // Handle add new case
      const addFn = (type: string, index: number) => {
        switch (type) {
          case 'gallery-page-element':
            addGalleryPageElement(index)
            break
          case 'triple-image-page-element':
            addTripleImagePageElement(index)
            break
          case 'text-page-element':
            addTextPageElement(index)
            break
        }
      }

      if (
        [
          'gallery-page-element',
          'triple-image-page-element',
          'text-page-element',
        ].includes(String(event.active?.id)) &&
        String(event.over?.id).includes(
          'droppable-page-element-list-new-element'
        )
      ) {
        const index = (event.over?.data.current as any).index
        addFn(event.active?.id as string, index)
        return
      }

      // For unknown reason, sometimes the element is undefined
      if (props.content.some(e => !e)) {
        return
      }

      // Handle reordering case
      const oldIndex = props.content.findIndex(
        element => element.id === event.active?.id
      )
      const newIndex = props.content.findIndex(
        element => element.id === event.over?.id
      )

      props.updateContent(arrayMove(props.content, oldIndex, newIndex))

      console.log({ event })
    },
    [
      addGalleryPageElement,
      addTextPageElement,
      addTripleImagePageElement,
      props,
    ]
  )

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={
        // This breaks without keyboard sensor for some reason
        useSensors(useSensor(MyPointerSensor), useSensor(KeyboardSensor))
      }
    >
      <Box style={{ display: 'flex', marginTop: '10px' }}>
        <Paper sx={{ padding: '10px', width: '50%' }}>
          <Box>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              Click or drag an element to add it to the page
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <DraggableElement id="gallery-page-element">
                <Button variant="contained">Gallery</Button>
              </DraggableElement>

              <DraggableElement id="triple-image-page-element">
                <Button variant="contained">Triple Image</Button>
              </DraggableElement>

              <DraggableElement id="text-page-element">
                <Button variant="contained">Text</Button>
              </DraggableElement>
            </Box>
          </Box>
          <DroppablePageElementList
            content={props.content}
            updateContent={props.updateContent}
          />
        </Paper>
        <Paper sx={{ padding: '10px' }}>
          bye
          <Box sx={{ margin: '0 15px' }}>
            <Divider
              orientation="vertical"
              sx={{ opacity: 1, backgroundColor: 'lightgrey' }}
            />
          </Box>
          hello
        </Paper>
      </Box>
    </DndContext>
  )
}

function DroppablePageElementList(props: {
  content: PageContent
  updateContent: (content: PageContent) => void
}) {
  const { active, setNodeRef } = useDroppable({
    id: `droppable-page-element-list`,
  })

  const showNewElementDropZones =
    active &&
    [
      'gallery-page-element',
      'triple-image-page-element',
      'text-page-element',
    ].includes(String(active?.id))

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      ref={setNodeRef}
      subheader={<ListSubheader>Drag to reorder page elements</ListSubheader>}
    >
      <SortableContext
        items={props.content}
        strategy={verticalListSortingStrategy}
      >
        {props.content.map((element, i) => {
          // Unsure why sometimes element is undefined
          if (!element) {
            return null
          }

          return (
            <>
              {showNewElementDropZones && (
                <DropPageElementComponent index={i} />
              )}
              <PageElementListItem
                key={i}
                element={element}
                onRemove={() => {
                  const newContent = props.content.slice()
                  newContent.splice(i, 1)
                  console.log('after remove', newContent)
                  props.updateContent(newContent)
                }}
                selected={element.id === active?.id}
                showTransition={!!active}
              />
            </>
          )
        })}
      </SortableContext>

      {showNewElementDropZones && (
        <DropPageElementComponent index={props.content.length} />
      )}
    </List>
  )
}

function PageElementListItem(props: {
  element: PageElement
  onRemove: () => void
  selected: boolean
  showTransition: boolean
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.element.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: props.showTransition ? transition : undefined,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'lightgrey',
    },
  }

  return (
    <ListItem
      selected={props.selected}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={props.onRemove}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={props.element.name}
        secondary={props.element.type}
      />
      <IconButton edge="end" aria-label="delete" onClick={props.onRemove}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  )
}

function DropPageElementComponent(props: { index: number }) {
  const { isOver, setNodeRef } = useDroppable({
    data: { index: props.index },
    id: `droppable-page-element-list-new-element-${props.index}`,
  })
  return (
    <Box
      ref={setNodeRef}
      sx={{
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        border: isOver ? '1px solid darkgrey' : '1px dashed lightgrey',
        cursor: isOver ? 'pointer' : 'default',
      }}
    >
      <Typography sx={{ fontSize: 16 }} gutterBottom>
        Drop to add page element
      </Typography>
    </Box>
  )
}

function DraggableElement(props: { children: React.ReactNode; id: string }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  })
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 10000,
      }
    : undefined

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </div>
  )
}
